import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [{
    path: '',
    redirect: '/terminal/device',
  },
  {
    path: '/login',
    component: () => import('../views/user/Login.vue'),
  },
  {
    path: '/index',
    component: () => import('../views/index/home/Home.vue'),
    meta: {
      showLayout: true,
      keepAlive: true
    }
  },
  {
    path: '/myProfile',
    component: () => import('../views/user/info/Info.vue'),
    meta: {
      showLayout: true,
      keepAlive: true
    }
  },
  {
    path: '/editPW',
    component: () => import('../views/user/editPW/EditPW.vue'),
    meta: {
      showLayout: true,
      keepAlive: true
    }
  },
  {
    path: '/terminal/line',
    component: () => import('../views/main/terminal/line/home/Home.vue'),
    meta: {
      showLayout: true,
      keepAlive: true
    }
  },
  {
    path: '/terminal/type',
    component: () => import('../views/main/terminal/type/home/Home.vue'),
    meta: {
      showLayout: true,
      keepAlive: true
    }
  },
  {
    path: '/terminal/type/:id',
    component: () => import('../views/main/terminal/type/one/One.vue'),
    meta: {
      showLayout: true,
    }
  },
  {
    path: '/terminal/device',
    component: () => import('../views/main/terminal/device/home/Home.vue'),
    meta: {
      showLayout: true,
      keepAlive: true
    }
  },
  {
    path: '/terminal/device/:id',
    component: () => import('../views/main/terminal/device/one/One.vue'),
    meta: {
      showLayout: true,
    }
  },
]

const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}

const router = new VueRouter({
  // mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router