import {
  request
} from "./request";

//登录
export function login(form) {
  return request({
    url: 'user/login',
    params: {
      username: form.username,
      password: form.password,
    }
  })
}

//获取用户帐号信息
export function getProfile() {
  return request({
    url: 'user/getinfo',
    params: {},
    data: {},
  })
}

//修改账户信息
export function editProfile(data) {
  return request({
    url: 'user/updateinfo',
    params: {},
    data
  })
}

//修改账户密码
export function editPW(params) {
  return request({
    url: 'user/updatepassword',
    method: 'post',
    params,
    data: {}
  })
}