import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import "./assets/css/theme/index.css";
Vue.use(ElementUI);

Vue.prototype.$bus = new Vue();

Vue.config.productionTip = false

import {
  getProfile
} from "@/network/user";

//登录拦截
router.beforeEach((to, from, next) => {
  if (to.path === '/login') {
    next()
  } else {
    if (localStorage.getItem("access_token") === null || localStorage.getItem("access_token").length < 36) {
      next({
        path: '/login'
      })
    } else {
      getProfile()
        .then((res) => {
          if (res.code === 0) {
            next()
          } else {
            ElementUI.Message({
              showClose: true,
              message: "登录状态过期，请重新登录",
              type: "error",
            });
            next({
              path: '/login'
            })
          }
        })
    }
  }
});

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')