<template>
  <div id="app">
    <el-aside v-if="isShow" width="255px">
      <Sidebar />
    </el-aside>

    <el-container>
      <el-header v-if="isShow" height="50px">
        <Navbar />
        <Tabs />
      </el-header>

      <div v-if="loading" class="preload">
        <div class="container">
          <p class="name">智能终端管理平台-优云物联</p>
          <p class="title">正在加载资源...</p>
          <p class="sub-title">初次加载资源可能需要较多时间 请耐心等待</p>
        </div>

        <div class="footer">
          <div>
            Copyright ©
            <a href="www.jxhmi.cn" target="_blank"> www.jxhmi.cn </a>,
            All Rights Reserved.
          </div>
          <div>一站式物联网人机交互解决方案</div>
        </div>
      </div>

      <el-main v-else :class="isShow ? 'main' : ''">
        <el-scrollbar>
          <div>
            <keep-alive>
              <router-view v-if="$route.meta.keepAlive" />
            </keep-alive>
            <router-view v-if="!$route.meta.keepAlive" />
          </div>
          <div class="signature">
            <div>Copyright © www.jxhmi.cn, All Rights Reserved.</div>
            <div>一站式物联网人机交互解决方案</div>
          </div>
        </el-scrollbar>
      </el-main>
    </el-container>
  </div>
</template>

<script>
import Sidebar from "./components/common/sidebar/Sidebar";
import Navbar from "./components/common/navbar/Navbar";
import Tabs from "./components/common/tabs/Tabs";

export default {
  components: {
    Sidebar,
    Navbar,
    Tabs,
  },
  data() {
    return {};
  },
  computed: {
    loading() {
      return this.$route.path === "/";
    },
    isShow() {
      return this.$route.meta.showLayout;
    },
  },
  created() {
    this.checkBrowser();
  },
  mounted() {},
  methods: {
    checkBrowser() {
      var theUA = window.navigator.userAgent.toLowerCase();
      console.log(theUA);
      if (
        (theUA.match(/msie\s\d+/) && theUA.match(/msie\s\d+/)[0]) ||
        (theUA.match(/trident\/?\d+/) && theUA.match(/trident\/?\d+/)[0])
      ) {
        var ieVersion =
          (theUA.match(/msie\s\d+/) &&
            theUA.match(/msie\s\d+/)[0].match(/\d+/)[0]) ||
          (theUA.match(/trident\/?\d+/) &&
            theUA.match(/trident\/?\d+/)[0].match(/\d+/)[0]);
        if (ieVersion < 11) {
          var str = "暂不支持较低版本的IE浏览器";
          var str2 =
            "推荐使用:<a href='https://www.baidu.com/s?ie=UTF-8&wd=%E8%B0%B7%E6%AD%8C%E6%B5%8F%E8%A7%88%E5%99%A8' target='_blank' style='color:blue;'>谷歌</a>、" +
            "<a href='https://www.baidu.com/s?ie=UTF-8&wd=%E7%81%AB%E7%8B%90%E6%B5%8F%E8%A7%88%E5%99%A8' target='_blank' style='color:blue;'>火狐</a>" +
            "或其他双核极速模式";
          document.writeln(
            "<pre style='text-align:center; height:100%;border:0;position:fixed;top:0;left:0;width:100%;z-index:1234'>" +
              "<h2 style='padding-top:200px;margin:0'><strong>" +
              str +
              "<br/></strong></h2><h2>" +
              str2 +
              "</h2><h2 style='margin:0'><strong>如果你的使用的是双核浏览器,请切换到极速模式访问<br/></strong></h2></pre>"
          );
          document.execCommand("Stop");
        }
      }
    },
  },
};
</script>

<style>
@import "assets/css/base.css";

.preload {
  display: flex;
  flex-direction: column;
  height: 100vh;
  width: 100vw;
  letter-spacing: 1px;
  background-color: #2f3447;
}

.preload .container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
  user-select: none;
  flex-grow: 1;
}

.preload .name {
  font-size: 30px;
  color: #fff;
  letter-spacing: 5px;
  font-weight: bold;
}

.preload .title {
  color: #fff;
  font-size: 14px;
  margin-bottom: 10px;
}

.preload .sub-title {
  color: #ababab;
  font-size: 12px;
}

.preload .footer {
  text-align: center;
  padding: 10px 0 20px 0;
}

.preload .footer * {
  font-size: 12px;
  color: #ababab;
  text-decoration: none;
}

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #303133;
}

#app .el-aside {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
}

#app .el-header {
  position: fixed;
  top: 0;
  left: 255px;
  right: 0;
  z-index: 1;

  padding: 0;

  background: #ffffff;
  box-shadow: 0px 6px 12px 0px rgba(230, 233, 240, 0.3);
}

#app .el-main {
  min-height: 100vh;

  padding: 0;

  background: #2f3447;
}
#app .el-main.main {
  min-height: calc(100vh - 100px);

  margin: 100px 0 0 255px;

  background: #f7f7f7;
}

#app .el-main.main > .el-scrollbar {
  height: calc(100vh - 100px);
}
#app .el-main.main > .el-scrollbar .el-scrollbar__wrap {
  overflow-x: hidden;
}
#app .el-main.main > .el-scrollbar > .el-scrollbar__wrap > .el-scrollbar__view {
  min-width: 700px;
}

.signature {
  margin: 10px auto;

  font-size: 12px;
  color: #fff;
  line-height: 20px;
}
.main .signature {
  color: #c0c4cc;
}

/* elementUI */
#app .el-avatar {
  background: transparent;
}

#app .el-input-number .el-input__inner {
  text-align: left;
}

.table .el-tooltip .el-button {
  width: 100%;

  overflow: hidden;
  text-align: left;
  text-overflow: ellipsis;
}

.table .img {
  width: 40px;
  height: 40px;

  vertical-align: middle;
  border-radius: 5px;
}

/* 选择图片并上传 */
.uploaded,
.upload {
  height: 128px;
  width: 128px;

  border: 1px dashed #d9d9d9;
  border-radius: 6px;
}
.uploaded:hover,
.upload:hover {
  color: #4165d7;
  border-color: #4165d7;
}

.uploaded .el-image,
.uploaded .action {
  height: 126px;
  width: 126px;

  border-radius: 6px;
}

.uploaded .action {
  display: flex;
  justify-content: space-around;
  align-items: center;

  position: absolute;
  left: 1px;
  top: 1px;

  padding: 0 25px;

  color: #fff;
  opacity: 0;
  font-size: 20px;
  background-color: rgba(0, 0, 0, 0.5);
  transition: opacity 0.3s;
}
.uploaded:hover .action {
  opacity: 1;
}
.uploaded .action i {
  cursor: pointer;
}

.upload {
  display: flex;
  justify-content: center;
  align-items: center;

  cursor: pointer;
}
.upload i {
  margin-right: 5px;

  font-size: 28px;
  color: #8c939d;
}

#app .preview .el-dialog__body {
  text-align: center;
}
</style>
