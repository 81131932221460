<template>
  <el-scrollbar>
    <el-menu
      class="sidebar"
      background-color="#2f3447"
      text-color="#ffffff"
      active-text-color="#ffffff"
      :default-active="$route.path"
      router
    >
      <el-image
        class="logo"
        :src="require('@/assets/img/logo-white.png')"
        fit="contain"
      />
      <el-menu-item index="/terminal/device">
        <i class="el-icon-s-opportunity" />
        <span slot="title">终端列表</span>
      </el-menu-item>
      <!-- <el-menu-item index="/index">
        <i class="el-icon-s-home" />
        <span slot="title">首页</span>
      </el-menu-item>
      <el-submenu index="/terminal">
        <template slot="title">
          <i class="el-icon-s-opportunity" />
          <span slot="title">终端管理</span>
        </template>
        <el-menu-item index="/terminal/line">
          <i class="el-icon-" />
          <span slot="title">终端系列</span>
        </el-menu-item>
        <el-menu-item index="/terminal/type">
          <i class="el-icon-" />
          <span slot="title">终端型号</span>
        </el-menu-item>
        <el-menu-item index="/terminal/device">
          <i class="el-icon-" />
          <span slot="title">终端列表</span>
        </el-menu-item>
      </el-submenu> -->
      <div class="edition">5.11 v0</div>
      <div style="height: 32px" />
    </el-menu>
  </el-scrollbar>
</template>

<script>
export default {
  data() {
    return {};
  },
  computed: {},
  created() {},
  mounted() {},
  methods: {},
};
</script>

<style scoped>
.el-scrollbar {
  height: 100vh;
}
.el-scrollbar >>> .el-scrollbar__wrap {
  overflow-x: hidden;
}

.sidebar {
  min-height: 100vh;

  border-right: none;
}

.logo {
  height: 30px;

  margin: 25px auto;
}

li {
  text-align: left;
}

.el-menu-item:hover,
.el-submenu >>> .el-submenu__title:hover,
.el-submenu >>> .el-menu-item:hover {
  background: #262a39 !important;
}
.el-menu-item.is-active,
.el-submenu >>> .el-menu-item.is-active {
  background: #4165d7 !important;
}

.el-submenu svg {
  margin: 0 8px 0 3px;
}

.edition {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;

  color: #fff;
  line-height: 2em;
}
</style>
